fieldset {
  &.is-invalid {
    > p {
      color: $danger;
    }
  }
}fieldset {
  &.is-invalid {
    > p {
      color: $danger;
    }
  }
}

textarea.form-control {
  min-height: $grid-gutter-width * 3;
}

.form-check {
  padding-left: $form-check-input-width + 0.938rem;

  .form-check-input {
    margin-left: -$form-check-input-width - 0.938rem;
    &:checked[type='checkbox'] {
      background: transparent escape-svg($form-check-input-checked-bg-image) no-repeat center center;
      background-size: 12px 12px;
    }
  }

  label {
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    padding-top: 1px;

    a {
      color: $input-color;
    }
  }
}

.form-floating {
  > .form-control,
  > label {
    border-radius: $input-border-radius;
    padding: $input-padding-y $input-padding-x;
  }

  > .form-select {
  }

  > .form-control {
    font-size: $input-font-size;
  }

  > .form-control:focus ~ label,
  > .form-control:not(:placeholder-shown) ~ label,
  > .form-control-plaintext ~ label,
  > .form-select ~ label {
    color: $light;
    font-weight: $font-weight-semibold;
  }

}

.validation-feedback.is-invalid {
  input:not(:valid),
  textarea:not(:valid) {
    border-color: $danger;
  }
  .invalid-feedback {
    display: block;
  }
}

.was-validated {

  .form-check-input,
  .form-select,
  .form-control {
    &:valid,
    &.valid {
      border-color: $input-border-color;
      box-shadow: none;
      &:focus {
        border-color: $input-border-color;
        box-shadow: none;
      }
    }
    &:invalid,
    &.is-invalid {
      border-color: $form-feedback-invalid-color;
      box-shadow: none;
      &:focus {
        border-color: $form-feedback-invalid-color;
        box-shadow: none;
      }
    }
  }
}

.valid-feedback,
.invalid-feedback {
  flex: 1 1 100%;
  font-weight: $font-weight-medium;
  margin: $form-feedback-margin-top 0 $form-feedback-margin-top * 2;
}
