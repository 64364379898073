.l-widget--employees {
  .card-employee {
    background-color: $lighter;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $darker;
      text-decoration: none;
      width: $grid-gutter-width;
      height: $grid-gutter-width;
      border-radius: $grid-gutter-width * 0.5;
      background-color: $body-bg;
      transition: $transition-base;
      &:hover,
      &:focus {
        background-color: $darker;
        .icon {
          stroke: $body-bg;
        }
      }
    }
    .icon {
      fill: transparent;
      stroke: $darker;
      stroke-width: 1.4;
      width: 50%;
      height: 50%;
    }
  }
}
