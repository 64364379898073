/*
* Turn font anti aliasing on or off in Chrome / FF
* @Usage @include font-smoothing(on);
* @Usage on black / dark backgrounds @include font-smoothing(off);
*/
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
