.c-card-property {
  border: solid 1px $light;
  h2 {
    .card-subtitle{
      color: $light;
      &:first-child {
        margin-bottom: 0.313rem;
      }
      &:last-child {
        margin-top: 0.313rem;
      }
    }
  }
  .label {
    position: relative;
    transform: translateY(-50%);
  }
}
.c-card-property.c-card-property-horizontal {
  .label {
    position: relative;
    transform: translateY(-50%);
    @include media-breakpoint-up(md) {
      transform: translateX(-100%);
      left: 0;
    }
  }
}
