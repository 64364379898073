.widget--carousel {

  $z-index-button: 100;
  $buttonSize: 50px;
  $dragIndicatorSizeXS: 120px;
  $dragIndicatorSizeLG: 170px;

  .drag-indicator {
    opacity: 1;
    transition: $transition-base;
    width: $dragIndicatorSizeXS;
    height: $dragIndicatorSizeXS;
    border-radius: $dragIndicatorSizeXS * 0.5;
    display: block;
    background-color: $darker;
    z-index: $z-index-button + 3;
    @extend %centerer;

    @include media-breakpoint-up(lg) {
      width: $dragIndicatorSizeLG;
      height: $dragIndicatorSizeLG;
      border-radius: $dragIndicatorSizeLG * 0.5;
    }

    span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      text-align: center;
      padding: $grid-gutter-width * 0.5;
      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width;
      }
    }
    &.btn-dotted {
      &:after {
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
      }
    }
    &.out {
      opacity: 0;
    }
  }

  .icon-wrapper {
    .icon {
      stroke: $darker;
      stroke-width: 1.6;
      fill: transparent;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    z-index: $z-index-button;
    width: $buttonSize;
    height: $buttonSize;
    border-radius: $buttonSize * 0.5;
    background-color: $white;
    border: solid 1px $light;

    &:before,
    &::after {
      display: none;
    }
  }

  .swiper-button-next {
    right: $grid-gutter-width * 1.5;
  }

  .swiper-button-prev {
    left: $grid-gutter-width * 1.5;
  }

  .swiper-slide {
    width: 70vw;
    max-width: 1200px;

    img {
      opacity: 0.5;
    }

    &.swiper-slide-active {
      img {
        opacity: 0.9;
      }
    }

    @include media-breakpoint-up(sm){
      width: 60vw;
    }

    @include media-breakpoint-up(md){
      width: 50vw;
    }
  }

}
