.l-errors {
  $bg: $lighter;
  $mobile-breakpoint: "lg";
  $animation-speed: 50s;

  display: flex;
  align-items: center;
  justify-content: center;
  background: $bg;
  min-height: 100vh;

  img {
    opacity: 0.2;
    width: 100vw;
    &.img-fluid {
      max-width: 200%;
      @include media-breakpoint-up($mobile-breakpoint) {
        max-width: 100%;
      }
    }
  }

  .scrolling-image-container {
    .scrolling-image {
      width: 400vw;
      animation: move-mobile $animation-speed linear infinite;
      img {
        width: 200vw;
        flex: 0 0 auto;
        @include media-breakpoint-up($mobile-breakpoint) {
          width: 100vw;
        }
      }
      @include media-breakpoint-up($mobile-breakpoint) {
        width: 200vw;
        animation: move-desktop $animation-speed linear infinite;
      }
    }
  }

  @keyframes move-mobile {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200%);
    }
  }

  @keyframes move-desktop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

}
