main#page-property-subscribe {

  form {
    .btn-group {
      > .btn.btn-outline-primary {
        font-weight: $font-weight-semibold;
        @include font-size($font-size-base);
        padding: $grid-gutter-width * 0.25 10px;
      }
    }
    .input-range {
      @include media-breakpoint-up(sm) {
        position: relative;
        &:after {
          content: '';
          width: $grid-gutter-width * 0.5;
          height: 1px;
          background-color: $input-border-color;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .btn-check:checked + .btn {
      color: $lighter;
    }
    .btn-check:focus + .btn,
    .btn-check:checked + .btn {
      box-shadow: none;
    }
  }

}
