.c-range--epc {
  $range-height: 6px;
  $range-border-radius: 0;
  $range-ticks-bp: 'md';
  $range-small-ticks-bp: 'lg';

  $thumb-color: $darker;
  $thumb-width: 16px;
  $thumb-height: 10px;

  $tick-height: 0.375rem;
  $tick-height-lg: $tick-height * 2;
  $tick-margin-top: 0.625rem;
  $tick-bg-color: $light;
  $tick-text-color: $darker;
  $tick-font-size: 0.75rem;
  $tick-font-weight: $font-weight-semibold;

  $color-1: rgba(45, 138, 74, 1);
  $color-2: rgba(189, 210, 70, 1);
  $color-3: rgba(236, 209, 47, 1);
  $color-4: rgba(251, 180, 81, 1);
  $color-5: rgba(245, 131, 59, 1);
  $color-6: rgba(229, 62, 62, 1);

  padding-top: 0;
  padding-bottom: $tick-height-lg * 1.5;

  input[type="range"] {
    appearance: none;
    width: 100%;
    height: $range-height;
    border-radius: $range-border-radius;
    background: linear-gradient(90deg, $color-1 18%, $color-2 34%, $color-3 50%, $color-4 66%, $color-5 82%, $color-6 98%);
    outline: none;
    opacity: 1;
    transition: opacity 0.2s;

    // must be in separate definition or it won't render
    &::-moz-range-thumb {
      -moz-appearance: none;
      appearance: none;
      width: 0;
      height: 0;
      background: none;
      border-radius: 0;
      transform: translate(45%, -$thumb-height * 0.5);
      border-style: solid;
      border-width: $thumb-height ($thumb-width * 0.5) 0 ($thumb-width * 0.5);
      border-color: $thumb-color transparent transparent transparent;
      box-shadow: none;
      cursor: default;
      outline: none;
    }

    // must be in separate definition or it won't render
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: $thumb-width;
      height: 0;
      background: none;
      border-radius: 0;
      transform: translate(0, -$thumb-height * 0.5);
      border-style: solid;
      border-width: $thumb-height ($thumb-width * 0.5) 0 ($thumb-width * 0.5);
      border-color: $thumb-color transparent transparent transparent;
      box-shadow: none;
      cursor: default;
      outline: none;
    }

    &:focus {
      outline: none;
    }
  }

  .ticks {
    display: none;

    @include media-breakpoint-up($range-ticks-bp) {
      display: flex;
      justify-content: space-between;
      color: $tick-text-color;
      font-size: $tick-font-size;
      font-weight: $tick-font-weight;
      height: $tick-height;
      margin: $tick-margin-top ($thumb-width * 0.5) 0 ($thumb-width * 0.5);
    }
  }

  .ticks > div {
    height: 100%;
    width: 1px;
    background: $tick-bg-color;
  }

  .ticks > div:nth-child(5n + 1) {
    @include media-breakpoint-up($range-small-ticks-bp) {
      height: $tick-height-lg;
    }

    &::before {
      display: flex;
      white-space: nowrap;
      justify-content: center;
      content: attr(data-tick);
      transform: translate(-50%, $tick-height);

      @include media-breakpoint-up($range-small-ticks-bp) {
        transform: translate(-50%, $tick-height-lg);
      }
    }
  }

  .ticks > div:not(:nth-child(5n + 1)) {
    display: none;

    @include media-breakpoint-up($range-small-ticks-bp) {
      display: block;
    }
  }
}
