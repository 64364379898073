$anim-speed: 0.25s;
$easing-in: ease-out;
$easing-out: ease-in;

.a-hover-opacity-img {

  display: inline-block;
  overflow: hidden;

  img {
    transition: all $anim-speed $easing-in;
  }

  &:hover img {
    opacity: 1 !important;
    transition-timing-function: $easing-out;
  }
}
