#shareAPIPolyfill-backdrop {
  background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity) !important;
}

#shareAPIPolyfill-container {
  .shareAPIPolyfill-header-title {
    background-color: $darker !important;
    color: $white !important;
    svg g path {
      fill: $white !important;
    }
  }
}
