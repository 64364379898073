main.page-project-detail {

  .c-property-specification {
    .icon {
      + span {
        @include media-breakpoint-between(md, xl) {
          display: none;
        }
      }
    }
  }

}
