.btn-circle {
  position: relative;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  .text-link {
    border-radius: 100%;
    display: flex;
    align-items: center;;
    text-align: center;
    justify-content: center;
    aspect-ratio: 1/1;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    letter-spacing: 0.063rem;
    @include font-size(12px);
    line-height: 1.5;
    color: $darker;
    transition: $transition-base;
    padding: 15px;
    &:not([class*='bg-']) {
      background-color: rgba($white, 0.5);
    }
  }
  &:not(.btn-dotted) {
    &:hover,
    &:focus,
    &:active {
      span {
        color: $light;
      }
    }
  }
}
.btn-dotted {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: $darker;
    -webkit-mask: escape-svg(url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-1 -1 160 160"><circle vector-effect="non-scaling-stroke" cx="79" cy="79" r="79" fill="transparent" stroke="black" stroke-width="2" style="stroke-dasharray:2, 12" /></svg>')) center;
    mask: escape-svg(url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-1 -1 160 160"><circle vector-effect="non-scaling-stroke" cx="79" cy="79" r="79" fill="transparent" stroke="black" stroke-width="2" style="stroke-dasharray:2, 12" /></svg>')) center;
    transition: $transition-base;
  }
  &:hover {
    &:after {
      background: $light;
    }
  }
}

.btn-success,
.btn-warning,
.btn-danger,
.btn-disabled,
.btn-default {
  padding: $btn-padding-y-sm $btn-padding-x-sm;
  border-radius: $btn-border-radius;
  color: $white;
  letter-spacing: 0.063rem;
  text-transform: uppercase;
  font-weight: $font-weight-semibold;
  @include font-size($btn-font-size-sm);
  border: 0;
}

.btn-default {
  background-color: $darker;
  &:hover,
  &:focus,
  &:active {
    background-color: $light;
  }
}

.btn-success,
.btn-warning,
.btn-danger {
  &,
  &:hover,
  &:focus {
    color: $white;
  }
}

.btn-stripe {

  $stripe-size: $btn-padding-x-sm;
  padding-left: $stripe-size * 2;
  position: relative;
  transition: $transition-base;
  text-align: left;

  &.w-100 {
    @include media-breakpoint-down(sm) {
      text-align: center;
      &:before {
        display: none;
      }
    }
  }
  &:before {
    content: '';
    display: block;
    height: 1px;
    width: $stripe-size;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: $white;
    transition: $transition-base;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: $darker;
    padding-left: $stripe-size;
    color: $white;
    &:before {
      width: $stripe-size * 0.5;
    }
  }
}

.btn-secondary {

  $circleWidth: $grid-gutter-width;

  position: relative;
  display: inline-block;
  color: $darker;
  padding: 20px;
  width: 100%;

  .circle,
  .text-link,
  .white-circle{
    position: absolute;
  }

  .circle {
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 100%;
    width: $circleWidth;
    box-shadow: 0 0 1px 1px $lighter;
    background-color: $lighter;
    transition: $transition-base;
  }

  .white-circle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: $grid-gutter-width;
    height: $grid-gutter-width;
    border-radius: 100%;
    background: $lighter;
    display: flex;
    transition: $transition-base;
    svg {
      margin: auto;
      stroke-width: 2.4;
    }
  }

  .text-link {
    top: 50%;
    transform: translateY(-50%);
    white-space: normal;
    z-index: 2;
    padding: $btn-padding-y-sm 0;
    transition: $transition-base;
    font-weight: $font-weight-semibold;
  }

  &:hover {
    &,
    span {
      color: $darker;
    }

    .circle {
      transform: scale(0);
    }

    .white-circle {
      transform: translate(-50%, -50%) scale(1);
      left: $circleWidth * 0.5;
    }

    .text-link {
      transform: translate(40px, -50%);
    }
  }

  &.btn-inverted {
    $lighter: #fff;
    color: $darker;
    .white-circle,
    .circle {
      background-color: $lighter;
    }
    .circle {
      box-shadow: 0 0 1px 1px $lighter;
    }
  }

}


.btn-icon-text {

  display: flex;
  align-items: center;
  padding: 0;
  border: 0;

  .icon {
    fill: transparent;
    stroke-width: 1.2;
    stroke: $darker;
    transition: $transition-base;
    + span {
      padding: 0 0 0 1rem;
      font-weight: $font-weight-medium;
      @include font-size(14px);
      color: $darker;
      text-align: left;
      transition: $transition-base;
    }
  }

  &:hover,
  &:focus,
  &:active {
    .icon {
      stroke: $light;
      + span {
        color: $light;
      }
    }
  }

  &[class*='bg-'] {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    border-radius: $btn-border-radius;
    letter-spacing: 0.063rem;
    font-weight: $font-weight-semibold;
    @include font-size($btn-font-size-sm);
    border: 0;
    svg {
      stroke: $white;
      stroke-width: 1.6;
    }
    span {
      color: $white;
    }
    &:hover,
    &:focus {
      .icon {
        stroke: $white;
      }
     &,
      span {
       color: $white;
     }
    }
  }

}

.btn-primary {
  position: relative;
  border: 0;
  span {
    position: relative;
    z-index: 1;
    @include font-size(15px);
    font-weight: $font-weight-semibold;
    text-transform: none;
  }
  &:not(.btn-lg) {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $light;
    width: 0;
    transition: $transition-base;
  }
  &:hover,
  &:focus,
  &:active {
    &:before {
      width: 100%;
    }
  }
}
.btn-link {
  color: $darker;
  text-transform: uppercase;
  letter-spacing: 0.063rem;
  font-weight: $font-weight-semibold;
  font-family: $font-family-sans-serif;
  transition: $transition-base;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    color: $light;
  }
}

.btn-outline {
  position: relative;
  box-shadow: inset 0 0 0 1px $darker;
  background-color: transparent;
  padding: $btn-padding-y-sm $btn-padding-x-sm;
  line-height: 1;
  &:before {
    content: '';
    display: block;
    background-color: $light;
    position: absolute;
    top: 1px;
    left: 1px;
    height: calc(100% - 2px);
    width: 0;
    z-index: 1;
    transition: $transition-base;
  }
  span {
    position: relative;
    z-index: 2;
    font-weight: $font-weight-semibold;
    @include font-size(14px);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border: 0;
  }
  &:hover,
  &:focus,
  &:active {
    &:before {
      width: calc(100% - 2px);
    }
  }
}

.btn {
  border-radius: $btn-border-radius;
  letter-spacing: 0.063rem;
}
.btn-none {
  padding: 0;
  border: 0;
  background-color: transparent;
}


.btn-underline {
  color: $darker;
  letter-spacing: 0.063rem;
  text-transform: uppercase;
  font-weight: $font-weight-semibold;
  @include font-size($btn-font-size-xs);
  border: 0;
  padding: 0;
  text-decoration: underline;
}
