.cms-text {
  word-break: break-word;

  p {
    line-height: 2;
    letter-spacing: 0.063rem;
    margin-bottom: 1.875rem;
  }

  li {
    letter-spacing: 0.063rem;
  }

  a {
    font-weight: 500;
    color: $darker;
    text-decoration: underline;
    strong {
      font-weight: 500;
      color: $darker;
    }
    &:hover,
    &:focus {
      color: $light;
    }
  }

  h2,
  h3,
  h4 {
    &,
    strong {
      color: $darker;
      font-weight: 400;
    }
  }

  ol,
  ul {
    margin-top: 0;
    margin-bottom: $grid-gutter-width * 0.5;
    padding-left: 13px;
    li {
      padding: 4px 0;
    }
  }

  p,
  ol,
  ul,
  h2,
  h3,
  h4,
  h5,
  table {
    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: $font-weight-semibold;
    color: $light;
  }

}

.intro {
  line-height: 2;
  letter-spacing: 0.063rem;
}

