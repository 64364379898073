main#page-estate-detail {

  .c-property-specifications {
    display: flex;
    a {
      color: $darker;
      &:hover,
      &:focus,
      &:active {
        color: $light;
      }
    }
    > div {
      border: solid 1px $darker;
      padding: 30px 10px;
      display: flex;
      justify-content: center;
      &:not(.c-property-address) {
        flex: 1;
        &:not(:first-child) {
          border-left: 0;
        }
      }
      &.c-property-address {
        flex: 0 0 100%;
        border-top: 0;
      }
    }
  }

  #intro {
    .c-property-specifications {
      margin-right: -$grid-gutter-width * 0.4;
      margin-left: -$grid-gutter-width * 0.4;
      > div {
        border: 0;
        padding: 0 $grid-gutter-width * 0.4;
        &:not(.c-property-address) {
          flex: 0 1 auto;
        }
        @include media-breakpoint-up(sm) {
          padding: 0 $grid-gutter-width * 0.5;
        }
      }
      @include media-breakpoint-up(sm) {
        margin-right: -$grid-gutter-width * 0.5;
        margin-left: -$grid-gutter-width * 0.5;
      }
    }
  }

  #page-intro {
    .property-price {
      &:not(:last-child) {
        padding-right: 1rem;
        border-right: solid 1px $dark;
        margin-right: 1rem;
      }
    }
    .w-75 {
      @include media-breakpoint-down(lg) {
        width: 100% !important;
      }
    }
  }

  #map {
    height: 50vh;
    @include media-breakpoint-up(lg) {
      height: 600px;
    }
  }

  #widget-container {
    .l-widget.no-background {
      &:first-child {
        margin-top: 0 !important;
      }
    }
  }

}
