.l-filter-bar {

  $gutter-x: 5px;
  $filter-active-border-color: $darker;

  margin: 0 (-$gutter-x);

  .filter-item {
    padding: 0 $gutter-x;
    margin-top: $gutter-x * 2;
    .badge {
      visibility: hidden;
    }
    &.active {
      .badge {
        visibility: visible;
      }
    }
    &[data-multiselect] {
      .dropdown-menu {
        .dropdown-item {
          &:hover,
          &:focus,
          &.active {
            color: $dropdown-link-active-color;
            background: $dropdown-link-active-bg escape-svg(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12 8.6' style='enable-background:new 0 0 12 8.6;' xml:space='preserve'%3E%3Cpath d='M0.8,4.3l3.4,3.5l7-7' fill='none' stroke='#{$dropdown-link-active-color}' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")) no-repeat right 24px center;
            background-size: 12px 8px;
          }
        }
      }
    }
  }

  .btn-filter {
    border: solid 1px $input-border-color;
    background-color: $input-bg;
    width: 100%;
    padding: $input-padding-y * 0.6 $input-padding-x;
    display: flex;
    align-items: center;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    color: $input-color;
    text-transform: none;
    text-align: left;
    flex: 1 1 auto;
    height: 100%;
    .icon {
      width: 14px;
      height: 14px;
      stroke: $input-color;
    }
    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $light;
      border-radius: 0;
      color: $white;
      min-width: 24px;
      height: 24px;
      text-align: center;
      font-weight: 600;
      border: solid 1px $light;
      padding: 2px 5px;
      line-height: 1;
      @include font-size(12px);
    }
    &:hover,
    &:focus {
      background-color: $input-bg;
      border-color: $filter-active-border-color;
    }
  }

  .dropdown-menu {
    border: solid 1px $dropdown-border-color;
    width: calc(100% - #{$gutter-x * 2});
    padding: 0;
    transform: translate(5px, -1px) !important;
    top: 100% !important;
    max-height: 30vh;
    overflow-y: auto;
    .dropdown-item {
      border-top: solid 1px $dropdown-divider-bg;
      font-weight: $input-font-weight;
    }
    li:first-child > .dropdown-item {
      border-top: 0;
    }
  }

}
