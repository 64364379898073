#c-property-specifications-panels {

  .accordion-item {
    background-color: transparent;
    .accordion-header {
      .accordion-button {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 0 $accordion-button-padding-y 0;
        @include font-size($h3-font-size);
        border-bottom: solid $accordion-border-width $accordion-border-color;
        box-shadow: none;
        .icon {
          position: relative;
          top: -1px;
        }
        &:after {
          display: none;
        }
        &[aria-expanded='true'] {
          .icon {
            transform: scaleY(-1);
          }
        }
      }
    }
    + .accordion-item {
      margin-top: $grid-gutter-width * 0.5;
    }
  }

}
