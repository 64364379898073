@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// Custom
$container-fluid-max-width: 120rem;
$z-index-cookies: 10000;

// Options
$enable-negative-margins: true;
$enable-validation-icons: false;
$enable-dark-mode: false;

// Color system
$primary: #161414;
$secondary: #F7EEE8;
$danger: #da3131;
$success: #3ecc86;
$lighter: $secondary;
$light: #CEC1B7;
$dark: #9B8A7DFF;
$darker: $primary;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "success": $success,
  "lighter": $lighter,
  "light": $light,
  "dark": $dark,
  "darker": $darker
);

// Spacing
$spacer: 0.625rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
  11: $spacer * 11,
  12: $spacer * 12,
  13: $spacer * 13,
  14: $spacer * 14,
  15: $spacer * 15,
  16: $spacer * 16,
  17: $spacer * 17,
  18: $spacer * 18,
  30: $spacer * 30,
);

$body-bg: $white;
$body-color: $darker;

// Grid columns
$grid-gutter-width: 2.5rem;
$container-padding-x: $grid-gutter-width;
$gutters: $spacers;

// Components
$border-radius:               0;

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "4x5": calc(5 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);

// Typography
$font-sizes: (
  12: 0.75rem,
  14: 0.875rem,
  15: 0.938rem,
  16: 1rem,
);

$font-family-sans-serif:  montserrat, sans-serif;
$font-family-serif:       ninna, serif;

$font-family-base: $font-family-sans-serif;
$font-size-base:  0.875rem;
$font-size-xs:  $font-size-base * 0.86;

$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            800;

$font-weight-base:            $font-weight-medium;

$line-height-lg: 2;

$headings-margin-bottom:  $spacer * 2;
$headings-font-family:    $font-family-serif;
$headings-font-style:     normal;
$headings-font-weight:    $font-weight-normal;
$headings-line-height:    1.4;
$headings-color:          $darker;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2.1;
$h3-font-size:                $font-size-base * 1.4;
$h4-font-size:                $font-size-base;
$h5-font-size:                $font-size-base * 0.71;

$display-font-sizes: (
  1: 3.125rem,
  4: 1.625rem
);

$display-font-family:   $headings-font-family;
$display-font-style:    $headings-font-style;
$display-font-weight:   $headings-font-weight;
$display-line-height:   $headings-line-height;

// Navbar
$navbar-nav-link-padding-x:         1.25rem;
$navbar-nav-link-padding-y:         0.625rem;


$navbar-brand-height: 102px;
$navbar-brand-padding-y: 0;
$navbar-brand-margin-end: 0;

$navbar-dark-color: $lighter;
$navbar-dark-hover-color: $lighter;
$navbar-dark-active-color: $lighter;

$navbar-light-color: $darker;
$navbar-light-hover-color: $light;
$navbar-light-active-color: $light;

// Dropdowns
$dropdown-font-size:          0.875rem;
$dropdown-color:              $darker;
$dropdown-bg:                 $white;
$dropdown-border-color:       $darker;
$dropdown-divider-bg:         $lighter;

$dropdown-link-color:         $darker;
$dropdown-link-hover-color:   $dark;
$dropdown-link-hover-bg:      $white;

$dropdown-link-active-color:  $dropdown-link-hover-color;
$dropdown-link-active-bg:     $dropdown-link-hover-bg;

$dropdown-item-padding-x:     1.5rem;
$dropdown-item-padding-y:     0.938rem;


// Pagination

$pagination-padding-y:              .375rem;
$pagination-padding-x:              .75rem;

$pagination-font-size:              $font-size-base ;

$pagination-color:                  $darker;
$pagination-bg:                     transparent;
$pagination-border-radius:          0;
$pagination-border-width:           0;

$pagination-focus-color:            $darker;
$pagination-focus-bg:               transparent;
$pagination-focus-box-shadow:       none;

$pagination-hover-color:            $white;
$pagination-hover-bg:               $darker;
$pagination-hover-border-color:     transparent;

$pagination-active-color:           $white;
$pagination-active-bg:              $darker;
$pagination-active-border-color:    transparent;

$pagination-disabled-color:         $light;
$pagination-disabled-bg:            transparent;
$pagination-disabled-border-color:  transparent;

// Buttons
$btn-padding-y-xs:            0.6rem;

$btn-padding-y-sm:            0.938rem;
$btn-padding-x-sm:            1.25rem;
$btn-font-size-xs:            0.65rem;
$btn-font-size-sm:            0.75rem;

$btn-border-width:            1px;

$btn-font-weight:             $font-weight-medium;
$btn-box-shadow:              none;
$btn-focus-width:             $btn-border-width;
$btn-focus-box-shadow:        $btn-box-shadow;
$btn-active-box-shadow:       $btn-box-shadow;

$btn-border-radius:           0;

$btn-padding-y-lg:            0.938rem;
$btn-padding-x-lg:            2.5rem;
$btn-font-size-lg:            0.75rem;

// Forms
$input-font-weight:           $font-weight-medium;

$input-padding-x:            1.5rem;
$input-padding-y:            1.25rem;
$input-font-size:            0.875rem;

$input-bg:                    $white;
$input-disabled-color:        $light;

$input-color:                 $darker;
$input-border-color:          $light;
$input-border-width:          0.063rem;
$input-box-shadow:            none;

$input-border-radius:             0;
$input-focus-border-color:        $light;
$input-focus-width:               0.063rem;
$input-focus-box-shadow:          none;

$input-placeholder-color:        $light;

$form-check-input-width:          1.375rem;
$form-check-min-height:           $form-check-input-width;

$form-check-input-bg:                   transparent;
$form-check-input-border:               $input-border-width solid $input-border-color;
$form-check-input-border-radius:        0;
$form-check-input-focus-border:         $light;
$form-check-input-focus-box-shadow:     none;
$form-check-input-checked-border-color: $light;
$form-check-indicator-color:            $darker;
$form-check-input-checked-bg-image:     url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12 8.6' style='enable-background:new 0 0 12 8.6;' xml:space='preserve'%3E%3Cpath d='M0.8,4.3l3.4,3.5l7-7' fill='none' stroke='#{$form-check-indicator-color}' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

$form-floating-height:            add(3.625rem, $input-height-border);
$form-floating-label-opacity:     1;
$form-floating-label-transform:   scale(0.85) translateY(-0.7rem) translateX(0.24rem);

$form-select-border-radius:       0;
$form-select-border-color:        $input-border-color;
$form-select-padding-y:           $input-padding-y;
$form-select-padding-x:           $input-padding-x;
$form-select-font-size:           $input-font-size;
$form-select-font-weight:         $input-font-weight;

$form-select-focus-box-shadow:    none;
$form-select-focus-border-color:  $light;
$form-select-bg-position:         right $input-padding-x center;
$form-select-bg-size:             14px 14px;
$form-select-indicator-color:     $darker;
$form-select-indicator:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.6 11.8'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M30.8,0.8l-15,10l-15-10'/></svg>");

$forms-select-focus-border-color:  $input-focus-border-color;
$form-select-focus-box-shadow:    none;

$form-feedback-margin-top:    0.625rem;


// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                0;
$nav-link-padding-x:                0;
$nav-link-color:                    $darker;
$nav-link-hover-color:              $darker;


$nav-pills-border-radius:           0;
$nav-pills-link-active-color:       $darker;
$nav-pills-link-active-bg:          transparent;
// scss-docs-end nav-variables


// Alerts
$alert-margin-bottom:           0.625rem * 2;
$alert-border-radius:           0;
$alert-link-font-weight:        $font-weight-medium;
$alert-border-width:            0;
$alert-bg-scale:                0;
$alert-border-scale:            0;
$alert-color-scale:             0;

// Cards

$card-spacer-x:                 2.5rem;
$card-spacer-y:                 2.5rem;
$card-border-width:             0;
$card-border-radius:            0;
$card-bg:                       $white;
$card-color:                    $darker;

// Accordion
$accordion-color:                         $darker;
$accordion-border-width:                  1px;
$accordion-border-color:                  rgba($light, 0.25);
$accordion-border-radius:                 0;
$accordion-inner-border-radius:           0;


$accordion-button-padding-y:              0.688rem;
$accordion-button-color:                  $accordion-color;
$accordion-button-bg:                     $lighter;
$accordion-button-active-bg:              $lighter;
$accordion-button-active-color:           $darker;

$accordion-button-focus-border-color:     $accordion-border-color;
$accordion-button-focus-box-shadow:       none;

// Modals

// scss-docs-start modal-variables
$modal-dialog-margin:               1rem;

$modal-backdrop-bg:                 $lighter;
$modal-backdrop-opacity:            .9;

$modal-content-border-radius:       0;


$modal-header-border-color:         $light;
$modal-header-border-width:         1px;

$modal-footer-bg:                   $lighter;
$modal-footer-border-color:         $modal-header-border-color;
$modal-footer-border-width:         $modal-header-border-width;

// scss-docs-end modal-variables

// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-horizontal-width:        100vw;
$offcanvas-bg-color:                $lighter;
$offcanvas-backdrop-bg:             $lighter;
$offcanvas-backdrop-opacity:        1;


// Tables
$table-cell-padding-y:        0.75rem;
$table-cell-padding-x:        0.938rem;
$table-bg:                    $body-bg;
$table-accent-bg:             $lighter;
