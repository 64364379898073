/*
* Covers an entire block
* @Usage @extend %coverer;
*/
%coverer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
* Centers block vertically and horizontally
* It works even when: You don't know know how big it is, You don't know how big the container is, either
* @Usage @extend %centerer;
*/
%centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
