.l-site-footer {

  $link-opacity: 1;

  & {
    @include font-size(12px);
    font-weight: $font-weight-medium;
    letter-spacing: 0.063rem;
  }

  &,
  a {
    color: rgba($navbar-light-color, $link-opacity);
    text-decoration: none;
  }

  a:hover,
  a:focus,
  a:active {
    color: $navbar-light-hover-color;
    text-decoration: none;
  }

  address {
    span,
    a {
      display: block;
      margin-top: 4px;
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .author-link:not(:hover):not(:focus):not(:active),
  .nav-social a:not(:hover):not(:focus):not(:active) {
    opacity: $link-opacity;
  }

  .nav-legal {
    li {
      a {
        display: block;
        padding: 0 $grid-gutter-width * 0.25;
        text-transform: uppercase;
        @include media-breakpoint-up(xl) {
          padding: 0 $grid-gutter-width * 0.5;
        }
        @include media-breakpoint-down(md) {
            color: $light;
        }
      }
    }
  }

  .list-contact {
    li {
      a {
        text-decoration: none;
        @include font-size($h2-font-size);
        line-height: $headings-line-height;
      }
      + li {
        margin-top: 10px;
      }
    }
  }

  .nav-social {
    .nav-item > a {
      border-color: $white;
    }
  }

  .list-logos {
    display: flex;
    flex-direction: row;
    li {
      padding: 0 $grid-gutter-width * 0.25;
      a {
        display: block;
      }
      img {
        width: 50px;
      }
      + li {
        margin-top: 5px;
      }
    }
    @include media-breakpoint-up(sm) {
      flex-direction: column;
    }
  }

  .meta {
    border-top: solid 1px $light;
    margin-top: $grid-gutter-width * 1.5;
    padding-top: $grid-gutter-width;
    .list-inline-striped {
      @include media-breakpoint-only(xs) {
        li {
          &:after {
            display: none;
          }
          a {}
          + li {
            margin-top: 8px;
          }
        }
        display: block;
      }
    }

  }
}
