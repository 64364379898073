main#page-home {

  #seo {
    hr {
      border-top: solid 1px $light;
      opacity: 1;
    }
  }

  #references {
    border-bottom: solid 1px $lighter;
    .img-wrapper {
      margin-top: -24px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
    @include media-breakpoint-up(md) {
      border: 0;
    }
  }

  #about {
    .btn-circle {
      width: 300px;
      position: absolute;
      right: 0;
      bottom: 4.375rem;
      z-index: 10;
      transform: translateX(50%);
      svg {
        stroke-width: 0.2;
        circle {
          stroke-dasharray: 0.3 2;
        }
      }
      .text-link {
        transform: rotate(90deg);
        padding-top: calc(50% - 20px);
      }
    }
    @include media-breakpoint-up(md) {
      min-height: 300px;
    }
  }

  #selling-point {
    .extend-bg.extend-bg-right {
      &:before {
        bottom: 50%;
        z-index: -1;
        @include media-breakpoint-up(md) {
          left: -3.75rem;
        }
        @include media-breakpoint-up(xl) {
          left: -6.25rem;
        }
      }
    }
    .img-wrapper {
      img {
        @include media-breakpoint-up(sm) {
          width: 75%;
        }
        @include media-breakpoint-up(xxl) {
          width: 50%;
        }
      }
    }
  }

  #gallery {
    @media screen and (min-width: 1700px) {
      margin-top: 6.25rem !important;
    }
  }

}
