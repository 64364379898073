.l-video {

  .sound-controls {

    $sound-control-size: 50px;

    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
    span {
      position: relative;
      width: $sound-control-size;
      height: $sound-control-size;
      border-radius: $sound-control-size * 0.5;
      background-color: $white;
      display: none;
      .icon {
        @extend %centerer;
        width: $sound-control-size * 0.5;
        height: $sound-control-size * 0.5;
        stroke-width: 1.6;
        transition: $transition-base;
      }
    }
    &:hover,
    &:focus,
    &:active {
      span {
        .icon {
          stroke: $light;
        }
      }
    }
    &.video-muted {
      .controls__icon--sound-off {
        display: block;
      }
      .controls__icon--sound-on {
        display: none;
      }
    }
    &:not(.video-muted) {
      .controls__icon--sound-off {
        display: none;
      }
      .controls__icon--sound-on {
        display: block;
      }
    }
  }

}
