.c-spec-item {
  color: $darker;
  > div {
    padding: $table-cell-padding-y $table-cell-padding-x;
    @include font-size(12px);
    line-height: 1.5;
    font-weight: $font-weight-medium;
    transition: $transition-base;
    background-color: rgba($white, 0.75);

  }
  &[class*='col-'] {
    &:nth-child(4n + 4),
    &:nth-child(4n + 3) {
      @include media-breakpoint-up(xl) {
        > div {
          background-color: transparent;
        }
      }
    }
    &:nth-child(even) {
      > div {
        @include media-breakpoint-down(xl) {
          background-color: transparent;
        }
      }
    }
  }
  &:not([class*='col-']) {
    &:nth-child(even) {
      > div {
        background-color: transparent;
      }
    }
  }
}
a.c-spec-item {
  color: $darker;
  .icon {
    fill: transparent;
    stroke: $darker;
    stroke-width: 2.8;
  }
  &:hover,
  &:focus,
  &:active {
    > div {
      padding: 12px 20px;
    }
  }
}
