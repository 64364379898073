.label {
  @include font-size($font-size-xs);
  display: block;
  padding: 0.75rem 0.875rem;
  color: $darker;
  background-color: $light;
  text-transform: uppercase;
  letter-spacing: 0.063rem;
  font-weight: $font-weight-medium;
  &.label-default {
    background-color: $darker;
    color: $white;
    &.label-inverted {
      border: solid 1px $darker;
      color: $darker;
      background-color: transparent;
    }
  }
  &.label-sm {
    padding: 0.5rem;
    @include font-size(10px);
    font-weight: $font-weight-semibold;
  }
}
