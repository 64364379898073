.nav-social {

  $social-icons-size: 3.125rem;
  $padding-x: 0.469rem;
  $padding-y: 0;

  margin: 0 (-$padding-x);

  .nav-item {
    padding: $padding-y $padding-x;
    > a {
      background-color: $white;
      width: $social-icons-size;
      height: $social-icons-size;
      border-radius: $social-icons-size * 0.5;
      border: solid 1px $light;
      .icon {
        width: 20px;
        height: 20px;
      }
      &:hover,
      &:focus,
      &:active {
        .icon {
          fill: $light;
        }
      }
    }
  }

}
