main#page-contact {

 aside {
   address,
   ul {
     strong {
       font-weight: $font-weight-semibold;
       color: $light;
       @include font-size($font-size-base);
     }
     a,
     span {
       color: $darker;
       display: block;
       letter-spacing: 0.063rem;
       font-weight: $font-weight-medium;
       text-decoration: none;
       line-height: 1.5;
       padding: 4px 0 0 0;
     }
     li:first-child > span,
     span:first-child {
       padding: 0;
     }
     a:hover,
     a:focus,
     a:active {
       text-decoration: underline;
     }
   }
   .legal {
     .fs-12 {
       line-height: 1.8;
     }
     p > span {
       display: block;
     }
     p > span,
     ul {
       margin-top: 4px;
     }
   }
 }

  #selling-point {
    .content-block {
      @include media-breakpoint-up(md) {
        &:before {
          content: '';
          display: block;
          background-color: $lighter;
          height: $grid-gutter-width * 3;
          width: 200vw;
          position: relative;
          transform: translateX(-50%);
          z-index: -12;
        }
      }
    }
  }

}
