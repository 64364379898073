#c-property-project-detail-tabs {

  .custom-table {
    .custom-row,
    .custom-head {
      background-color: $table-accent-bg;
      .custom-cell {
        flex: 1;
        padding: $table-cell-padding-y $table-cell-padding-x;
        @include font-size(12px);
        line-height: 1.5;
        font-weight: $font-weight-medium;
        transition: $transition-base;
      }
      &:nth-child(even) {
        background-color: $table-bg;
      }
    }
    .custom-head {
      background-color: $darker;
      color: $body-bg;
    }
    .custom-row {
      display: flex;
      align-items: center;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        border: solid 1px $lighter;
        background-color: $body-bg;
        padding-top: $table-cell-padding-y;
        .custom-cell {
          flex: 0 0 50%;
          padding-top: 0;
          > span {
            display: block;
            font-weight: 600;
          }
          &.custom-cell-cta {
            padding: 0;
            border-top: solid 1px $lighter;
            text-align: center;
            font-weight: 600;
            @include font-size(13px);
            flex: 0 0 100%;
            .label,
            a.btn-underline {
              display: block;
              width: 100%;
              height: 100%;
              text-decoration: none;
              background-color: $lighter;
              text-align: center;
              padding: $table-cell-padding-y $table-cell-padding-x;
              border: 0;
            }
            a.btn-underline {
              text-decoration: underline;
            }
          }
        }
        + .custom-row {
          margin-top: $grid-gutter-width;
        }
      }
    }
  }
}
