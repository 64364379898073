.pagination {
  $page-link-min-size: 40px;
  $gx: 5px;

  margin-left: -$gx;
  margin-right: -$gx;


  @include media-breakpoint-only(xs) {
    .page-item:not(.page-item--icon) {
      display: none !important;
    }

    .page-item--icon {
      flex: 1;
      a {
        width: 100%;
      }
    }
  }

  .page-item {
    padding-left: $gx;
    padding-right: $gx;
  }

  .page-item--icon .page-link {
    min-width: 80px;
  }

  .page-item--icon {
    svg {
      stroke-width: 3;
    }
    &:not(.disabled) {
      .page-link {
        .icon {
          transition: $transition-base;
        }
        &:hover,
        &:focus {
          background-color: transparent;
          .icon {
            position: relative;
            right: -5px;
            &.reflect-x {
              right: auto;
              left: -5px;
            }
          }
        }
      }
    }
  }

  .page-item.disabled {
    pointer-events: none;
  }

  .page-counter {
    text-align: center;
    letter-spacing: 0.063rem;
    strong {
      font-weight: $font-weight-semibold;
    }
  }

  .page-item.disabled,
  .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: $page-link-min-size;
    min-height: $page-link-min-size;
    font-family: $font-family-sans-serif;
    line-height: $line-height-sm;
    @include font-size($font-size-base);
  }

  .page-item.disabled {
    color: $pagination-disabled-color;
    svg {
      stroke: $pagination-disabled-color;
    }
  }

}
