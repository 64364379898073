
$tagMargin: 0.625rem 0.625rem 0 0;
$tag-pad: 0.75rem;
$tag-min-width: 1ch;
$tag-max-width: auto;
$tag-text-color: $white;
$tags-focus-border-color: $input-border-color;
$tag-bg: $darker;
$tag-hover: $light;
$tag-remove: $light;
$tag-close-size: 8px;
$tag-close-stroke-width: 4;
$tag-close-stroke-color: $white;

.tagify {

  &.tagify--outside {

    border-width: 0;

    > span {
      border: solid $input-border-width $input-border-color;
      color: $input-color;
      background: escape-svg(url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 31.1 31.1' style='enable-background:new 0 0 31.1 31.1;' xml:space='preserve'%3E%3Cg stroke='#{$darker}' stroke-width='2' stroke-linecap='round' fill='none'%3E%3Ccircle class='st0' cx='11.8' cy='11.8' r='11'/%3E%3Cpath class='st0' d='M30.3,30.3L19.6,19.2'/%3E%3C/g%3E%3C/svg%3E%0A")) no-repeat right $input-padding-x center;
      background-size: 20px 20px;
      &:before {
        color: $input-placeholder-color;
      }
    }

    .tagify__input {
      order: -1;
      flex: 100%;
      margin: 0 0 0.625rem 0;
      padding: $input-padding-y $input-padding-x;
      &:empty {
        &:before {
          opacity: 1;
        }
      }
      &:focus:empty {
        &:before {
          color: $input-placeholder-color;
        }
      }
    }

  }

}


.tagify__tag {
  background-color: $tag-bg;
  margin: $tagMargin;
  > div {
    padding: $tag-pad;
    .tagify__tag-text {
      font-weight: $font-weight-medium;
      letter-spacing: 0.063rem;
      text-transform: uppercase;
      @include font-size(12px);
    }
  }
  .tagify__tag__removeBtn {
    width: $tag-close-size;
    height: $tag-close-size;
    margin-right: $tag-pad;
    &:after {
      content: '';
      width: $tag-close-size;
      height: $tag-close-size;
      background: escape-svg(url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 30' style='enable-background:new 0 0 30 30;' xml:space='preserve'%3E%3Cg stroke-width='#{$tag-close-stroke-width}' stroke-linecap='round' stroke-linejoin='round' stroke='#{$tag-close-stroke-color}'%3E%3Cpath class='st0' d='M1,28.9c0,0,9.3-9.3,28-27.9'/%3E%3Cpath class='st0' d='M29,28.9L1,1.1'/%3E%3C/g%3E%3C/svg%3E%0A")) no-repeat center center;
      background-size: $tag-close-size $tag-close-size;
    }
    &:hover,
    &:focus {
      background-color: $tag-hover;
    }
  }
  &:hover,
  &:focus {
    background-color: $tag-hover;
  }
}

.tagify__dropdown.region-select {
  position: absolute !important;
  top: 58px !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
}

.tagify__dropdown__wrapper {
  border-color: $input-border-color;
}

.tagify__dropdown__item{
  color: $darker;
  background-color: $white;
  font-weight: 500;
  border-radius: 0;
  margin: 0;
  padding: $input-padding-x;
  display: block;
  &:hover{
    background-color: $darker;
    border: 0;
    color: $white;
  }

}

.tagify__dropdown__item--active{
  color: $white;
  background-color: $darker;
}


