.widget--call-to-action {
  @include media-breakpoint-up(md) {
    hr {
      margin: 0;
      width: 25%;
      border-top: solid 1px $light;
      + div {
        width: 75%;
      }
    }
  }
}
