.circle-title {

  $circle-radius: 8.75rem;
  position: relative;

  svg {
    display: block;
    fill: transparent;
    stroke: $lighter;
    stroke-width: 0.4;
    width: $circle-radius;
    height: $circle-radius;
    position: absolute;
    top: 0;
    left: 0;
    @include media-breakpoint-only(xs) {
      display: none;
    }
  }

}
