.c-property-specification {
  display: flex;
  align-items: center;
  .icon {
    fill: transparent;
    stroke-width: 1.2;
    stroke: $darker;
    + span {
      padding: 0 0 0 1rem;
      font-weight: $font-weight-medium;
      @include font-size(14px);
    }
  }
  &.flex-column {
    .icon + span {
      padding: 1rem 0 0 0;
    }
  }
}
a.c-property-specification {
  .icon {
    stroke: $darker;
    transition: $transition-base;
  }
  &:hover,
  &:focus {
    .icon {
      stroke: $light;
    }
  }
}
