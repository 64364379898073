.l-widget--quote {
  position: relative;

  .h1 {
    background-color: $white;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
      max-width: 80%;
    }
  }

  > div {
    &:after {
      content: '';
      display: block;
      height: 1px;
      background-color: $light;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      z-index: -1;
    }
  }
}
