.l-site-nav {

  $nav-breakpoint: 'md';
  $offcanvas-top: 0; // could also be navbar height
  $offcanvas-y: 3.438rem;
  $backgroundOpacity: 0.9;

  $navHeight: 140px;
  $navHeightMD: 160px;
  $navHeightLG: 182px;

  transform: translateY(0);
  opacity: 1;
  z-index: $zindex-offcanvas;
  background-color: $lighter;
  transition: all cubic-bezier(0.455, 0, 0.515, 1) 0.25s;

  a {
    color: $navbar-light-color;
    text-decoration: none;
  }

  nav {

    margin-right: -($navbar-nav-link-padding-x * 0.25);
    margin-left: -($navbar-nav-link-padding-x * 0.25);

    a {
      letter-spacing: 0.063rem;
      display: flex;
      align-items: center;
      padding: $navbar-nav-link-padding-y $navbar-nav-link-padding-x * 0.25;

      &:hover,
      &:focus,
      &.active {
        color: $light;
        font-weight: $font-weight-semibold;
      }
    }
  }

  .navbar-brand {
    img {
      height: 5rem;
      max-width: 18.75rem;
      @include media-breakpoint-up(lg) {
        height: 6.375rem;
      }
    }

    @include media-breakpoint-up($nav-breakpoint) {
      + div {
        margin-bottom: -0.2rem;
      }
    }
  }

  .divider {
    width: 1.875rem;
    height: 0.063rem;
    background-color: $light;
    @include media-breakpoint-only(md) {
      width: 1.5rem;
    }

    &.divider-sm {
      width: 3rem;
    }
  }

  .hamburger {
    position: relative;
    top: 2px;
    margin-left: 0.25rem;
  }

  .navbar-languages {
    @include media-breakpoint-down(md) {
      padding-right: $grid-gutter-width * 0.5;
    }

    a {
      @include media-breakpoint-down(md) {
        padding: $navbar-nav-link-padding-y $grid-gutter-width * 0.25;
      }
    }

    @include media-breakpoint-down(md) {
      border-right: solid 1px $light;
    }
  }

  .offcanvas {
    border-left: 0;

    @include media-breakpoint-up($nav-breakpoint) {
      position: static;
      visibility: visible !important;
      background: none;
      border: 0;
      transform: none;
      width: auto;
      margin-left: auto;
    }

    @include media-breakpoint-down($nav-breakpoint) {
      width: $offcanvas-horizontal-width;
      height: 100vh;
      background-color: $offcanvas-bg-color;
      padding-top: $offcanvas-y;
      padding-bottom: $offcanvas-y;
      .list-inline-striped {
        li:after {
          background-color: $light;
        }
      }
      .nav-social {
        .nav-item > a {
          background-color: transparent;
        }
      }
    }
  }

  .offcanvas .btn-close {
    background-color: rgba($white, 0.5);
    top: $grid-gutter-width * 0.5;
    right: $grid-gutter-width * 0.5;
    border-radius: 50%;

    .icon {
      stroke-width: 3;
    }

    line-height: 1;
    @include media-breakpoint-up($nav-breakpoint) {
      display: none;
    }
  }

  .nav-link {
    @include font-size(14px);
    line-height: 1;
    font-weight: $font-weight-semibold;
    position: relative;
    transition: $transition-base;
    @include media-breakpoint-up($nav-breakpoint) {
      padding-top: 10px;
      &.active,
      &:hover,
      &:focus {
        color: $light;
      }
    }
    @include media-breakpoint-down($nav-breakpoint) {
      padding: $navbar-nav-link-padding-y * 2 $navbar-nav-link-padding-x;
      text-align: center;
      font-family: $headings-font-family;
      @include font-size($h2-font-size);
      font-weight: $headings-font-weight;
    }
  }

  .container-fluid {
    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }

  &.navbar-expand-md {
    .navbar-nav {
      .nav-item {
        @include media-breakpoint-up(md) {
          .nav-link {
            padding-right: $navbar-nav-link-padding-x * 0.5;
            padding-left: $navbar-nav-link-padding-x * 0.5;
          }
          &:first-child .nav-link {
            padding-left: 0;
          }
          &:last-child .nav-link {
            padding-right: 0;
          }
        }
        @include media-breakpoint-up(lg) {
          .nav-link {
            padding-right: $navbar-nav-link-padding-x;
            padding-left: $navbar-nav-link-padding-x;
          }
        }
      }
    }
  }

  &.state-scrolled-up,
  &.bg-transparent {
    background-color: transparent;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      z-index: -1;
    }

    &:before {
      height: 30px;
      background-color: rgba($white, $backgroundOpacity);
      top: 0;
      @include media-breakpoint-up(md) {
        height: 40px;
      }
    }

    &:after {
      top: 30px;
      height: calc(100vh - 30px);
      transition: $transition-base;
      max-height: 130px;
      @include gradient-y($start-color: rgba($white, $backgroundOpacity), $end-color: rgba($white, 0), $start-percent: 30%, $end-percent: 100%);
      @include media-breakpoint-up(sm) {
        max-height: 420px;
      }
      @include media-breakpoint-up(md) {
        top: 40px;
        height: calc(40vh - 40px);
      }
    }
  }

  &:not(.bg-transparent) + div#skip-to-content {
    padding-top: $navHeight;
    &:before {
      content: '';
      display: block;
      background-color: $lighter;
      position: fixed;
      top: $navHeight;
      right: 0;
      left: 0;
      z-index: -1;
      height: 100px;
      opacity: 1;
    }
    @include media-breakpoint-up(md) {
      padding-top: $navHeightMD;
      &:before {
        top: $navHeightMD;
      }
    }
    @include media-breakpoint-up(lg) {
      padding-top: $navHeightLG;
      &:before {
        top: $navHeightLG;
      }
    }
  }

  &.state-scrolled-down {
    transform: translateY(-100%);
    opacity: 0;
    &:not(.bg-transparent) + div#skip-to-content {
      &:before {
        opacity: 0;
      }
    }
  }

  &.state-scrolled-up {
    &:not(.bg-transparent) {
      background-color: transparent;
      + div#skip-to-content {
        &:before {
          opacity: 0;
        }
      }
    }
    &.bg-transparent {
      background-color: rgba($body-bg, 0.5) !important;
      &:after {
        max-height: 0;
      }
    }
  }

}
