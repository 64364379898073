#sticky-patch {

  $iconSize: 24px;
  $padding: 20px;

  background-color: $black;
  position: fixed;
  top: 50%;
  right: 0;
  color: $white;
  padding: $padding;
  gap: $padding;
  font-weight: $font-weight-semibold;
  transform: translate(calc(100% - $iconSize - $padding - $padding), -50%);
  transition: $transition-base;

  svg {
    stroke-width: 1.5;
  }

  &:hover,
  &:focus {
    transform: translate(0, -50%);
  }

}
