.l-step {

  $icon-size: 100px;
  width: auto;
  flex: 0 0 100%;
  @include media-breakpoint-up(lg) {
    flex: 0 0 70%;
  }
  @include media-breakpoint-up(xl) {
    flex: 0 0 50%;
  }

  > div {

    width: calc(100vw - 80px);
    max-width: 600px;

  }
  .icon-wrapper {
    position: relative;
    width: $icon-size * 0.75;
    height: $icon-size * 0.75;
    border-radius: $icon-size;
    background-color: $white;
    @include media-breakpoint-up(md) {
      width: $icon-size;
      height: $icon-size;
    }
    .icon {
      stroke-width: 1.2;
      width: $icon-size * 0.4;
      height: $icon-size * 0.4;
      @extend %centerer;
      @include media-breakpoint-up(md) {
        width: $icon-size * 0.5;
        height: $icon-size * 0.5;
      }
    }
  }
  &:first-child,
  &:last-child {
    flex: 0 0 0;
    @include media-breakpoint-up(lg) {
      flex: 0 0 15%;
    }
    @include media-breakpoint-up(xl) {
      flex: 0 0 25%;
    }
  }
}

.steps-wrapper {
 @include media-breakpoint-up(lg) {
   &:before,
   &:after {
     content: '';
     display: block;
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     width: 20%;
     background: linear-gradient(90deg, rgba($lighter, 1) 0%, rgba($lighter, 0) 100%);
     z-index: 10;
   }
   &:after {
     right: 0;
     left: auto;
     transform: scaleX(-1);
   }
 }
  @include media-breakpoint-up(xl) {
    &:before,
    &:after {
      width: 50%;
    }
  }
}
