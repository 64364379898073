.bg-none {
  background: none !important;
}

.list-inline-striped {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    display: flex;
    align-items: center;
    padding: 0;
    flex: 0 1 auto;
    &:after {
      content: '';
      display: flex;
      width: $grid-gutter-width * 0.5;
      height: 1px;
      background-color: $darker;
      margin: 0 $grid-gutter-width * 0.5;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  &.list-xs {
    li {
      &:after {
        width: $grid-gutter-width * 0.25;
        margin: 0 $grid-gutter-width * 0.25;
      }
    }
  }
}

.extend-bg {
  &:before {
    content: '';
    display: block;
    background-color: $lighter;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 1;
  }
  > div.position-relative{
    z-index: 2;
  }
  &.extend-bg-right {
    &:before {
      right: auto;
      left: 100%;
    }
  }
}

.btn-offset {
  transform: translateY(-50%);
}

.ls-1 {
  letter-spacing: 0.063rem;
}

.fs-12 {
  line-height: 1.5;
}

.fs-16 {
  line-height: 1.5;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.rotate-n18O {
  transform: rotate(-180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.reflect-x {
  transform: scaleX(-1);
}

.mw-none {
  max-width: none !important;
}

.text-transform-none {
  text-transform: none !important;
}
