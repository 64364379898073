$baguettebox-overlay-bg:              $modal-backdrop-bg;
$baguettebox-overlay-opacity:         0.95;
$baguettebox-image-box-shadow:        none;
$baguettebox-button-color:            $darker;
$baguettebox-button-hover-color:      $light;
$baguettebox-button-bg:               $white;
$baguettebox-button-size:             3.125rem;

#baguetteBox-overlay {
  background-color: rgba($baguettebox-overlay-bg, $baguettebox-overlay-opacity) !important;
  .full-image {
    img {
      box-shadow: $baguettebox-image-box-shadow;
      max-width: 90%;
      max-height: 90%;
    }
  }
}

.baguetteBox-button {
  &#close-button {
    background-color: $baguettebox-button-bg;
    padding: $grid-gutter-width * 0.5;
    border-radius: $baguettebox-button-size * 0.5;
    width: $baguettebox-button-size;
    height: $baguettebox-button-size;
    top: $grid-gutter-width * 0.5;
    right: $grid-gutter-width * 0.5;
    svg {
      left: 50%;
      top: 50%;
      transform: scale(0.75) translate(-50%, -50%);
      transform-origin: top left;
      g {
        line {
          stroke-width: 2;
          stroke: $baguettebox-button-color;
        }
      }
    }
    &:focus,
    &:hover {
      svg g line {
        stroke: $baguettebox-button-hover-color;
      }
    }
  }
  &:not(#close-button) {
    background-color: transparent;
    svg {
      polyline {
        stroke: $baguettebox-button-color;
        stroke-width: 2;
      }
    }
    &:hover,
    &:focus {
      svg polyline {
        stroke: $baguettebox-button-hover-color;
      }
    }
  }
}
