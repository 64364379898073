#instagram {
  overflow: hidden;
  .grid {
    .row:last-child {
      position: relative;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 50%;
        width: 50vw;
        background-color: $lighter;
        z-index: -1;
        transform: translateX(-50vw);
      }
      &:after {
        transform: none;
      }
    }
  }
}
