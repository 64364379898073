$anim-speed: 0.3s;
$anim-easing: ease-in-out;
$anim-iteration-count: infinite;

.a-hover-rotate-swing-parent {
  .a-hover-rotate-swing-child {
    animation: rotate-swing $anim-speed 1 reverse;
  }
  &:hover {
    .a-hover-rotate-swing-child {
      animation: rotate-swing $anim-speed $anim-iteration-count forwards;
    }
  }
}

@keyframes rotate-swing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
