#c-property-specifications-tabs {
  .nav-pills {
    .nav-link {
      opacity: 0.25;
      transition: $transition-base;
      padding: $grid-gutter-width * 0.25 0;

      &.active,
      &:focus,
      &:hover,
      &:active {
        background: transparent;
        opacity: 1;
        @include media-breakpoint-down(lg) {
          background: $darker;
          .h2 {
            color: $white;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        opacity: 1;
        flex: 0 1 auto;
        padding: $grid-gutter-width * 0.25;
        border: solid 1px $darker;
        margin: 0 10px 10px 0;
        .h2 {
          font-family: $font-family-sans-serif;
          font-weight: $font-weight-semibold;
          @include font-size($font-size-base);
          letter-spacing: 0.063rem;
        }
      }

    }
    @include media-breakpoint-down(xl) {
      display: flex;
    }
    @include media-breakpoint-up(xl) {
      margin-top: -($grid-gutter-width * 0.25);
      margin-bottom: -($grid-gutter-width * 0.25);
    }
  }
  .tab-pane {
    @include media-breakpoint-up(xxl) {
      max-width: 900px;
      margin-right: 0;
      margin-left: auto;
    }
  }
  .tab-content {
    @include media-breakpoint-down(xl) {
      width: 100%;
    }
  }
}
