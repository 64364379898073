* {
  @include font-smoothing(on);

  &::selection {
    color: $body-bg;
    background: lighten($primary, 10%);
  }
}

html {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

body {
  word-break: break-word;

}

main {
  flex-shrink: 0;
}

.container-fluid {
  max-width: $container-fluid-max-width;
  padding-left: $grid-gutter-width * 0.5;
  padding-right: $grid-gutter-width * 0.5;
  @include media-breakpoint-up(sm) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
  @include media-breakpoint-up(lg) {
    padding-left: $grid-gutter-width * 1.5;
    padding-right: $grid-gutter-width * 1.5;
  }
  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width * 2;
    padding-right: $grid-gutter-width * 2;
  }
}

abbr[title] {
  border: 0;
  text-decoration: none;
}
