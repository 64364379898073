skarabee-searchprofile-component {

  $iconSize: 1.5rem;

  .icon-euro,
  .icon-step-house,
  .icon-step--location,
  .icon-tags,
  .icon-bed,
  .icon-user-circle-o {
    align-items: center;
    justify-content: center;
    display: none;

    &:before {
      content: '';
      display: block;
      width: $iconSize;
      height: $iconSize;
      background: $white url("../../../img/money.svg") no-repeat center center;
    }
  }

  .icon-step-house:before {
    background: $white url("../../../img/house.svg") no-repeat center center;
  }

  .icon-step--location:before {
    background: $white url("../../../img/location.svg") no-repeat center center;
  }

  .icon-tags:before {
    background: $white url("../../../img/tag.svg") no-repeat center center;
  }

  .icon-bed:before {
    background: $white url("../../../img/bed.svg") no-repeat center center;
  }

  .icon-user-circle-o:before {
    background: $white url("../../../img/account.svg") no-repeat center center;
  }

  .btn-primary {
    margin-top: 0 !important;

    &:before {
      z-index: -1;
    }
  }

  .subscribe-form--default {
    .step__btn.active {
      a {
        border-color: $dark;
      }
    }
  }

  .wizard {
    .stepbuttons {
      padding: 1.875rem 2.5rem;
      @media (min-width: 576px) {
        padding: 1.875rem 3.75rem !important;
      }
    }
  }

  .dropdown-menu {
    width: 100% !important;
    border-radius: 0;
    padding: 0;

    li {
      .dropdown-item {
        font-weight: $font-weight-semibold;
      }

      + li {
        .dropdown-item {
          border-top: solid 1px $light;
        }
      }
    }
  }

  .btn-group {
    width: 100% !important;

    > .btn {
      flex: 1 1 50% !important;
      @include font-size(14px);
      font-weight: 600;
    }
  }

  .form-group {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;

    .form-control {
      margin: 0 !important;

      + .dropdown-menu {
        width: 300px !important;
        top: -1px !important;
      }
    }
  }

  .badge {
    &.badge-secondary {
      background-color: $dark;
      text-decoration: none;
      @include font-size(14px);
      margin: 0 !important;
      padding: 10px !important;
      font-weight: 600;

      &:hover,
      &:focus {
        background-color: $light;
        color: $white;
      }
    }
  }

  .selectedcities {
    margin: 40px 0 0 0;
    display: flex;
    align-items: flex-start;
    padding: 0 !important;
    justify-content: flex-start !important;
    gap: 10px;
  }

  .priceContainer {
    skarabee-ui-input-form-field {
      flex: 0 0 50%;

      + skarabee-ui-input-form-field {
        .form-control {
          border-left: 0;
        }
      }
    }
  }

  .criteria {
    padding-top: 20px !important;
  }

  .row-remarks,
  .row {
    margin: 0 !important;
    padding: 0 !important;

    [class*='col-'],
    .row-item {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    [class*='col-'] {
      margin-bottom: 10px !important;
      width: 100%;
      @media (min-width: 576px) {
        &.col-sm-6,
        &.col-md-5,
        &.col-md-1 {
          width: calc(50% - 5px) !important;
          + .col-md-1,
          + .col-md-5,
          + .col-sm-6 {
            margin-left: 10px;
          }
        }
      }
    }

  }

  skarabee-checkbox-button {
    margin: 20px 0 0 0;

    .icon-check-empty,
    .icon-check {
      width: 30px !important;
      height: 30px !important;
      border: solid 1px $dark;
    }

    .icon-check {
      background: $white url("../../../img/check.svg") no-repeat center center;
      background-size: 15px;
    }
  }

  ngb-highlight {

  }

}

