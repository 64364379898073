skarabee-ui-recaptcha {
  padding-top: .5rem
}

skarabee-searchprofile-component .step {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-start;
  align-items: center;
  > div {
    width: 100%;
  }
}

skarabee-searchprofile-component .step-steps {
  margin-bottom: 0;
  width: 100%;
  display: none;
  @media (min-width: 768px) {
    display: block !important;
    padding: 3.75rem 0 0 0 !important;
  }
}

skarabee-searchprofile-component .step-btns__moving-wrapper {
  display: flex;
  position: relative;
  transition: all .48s ease-in-out
}

skarabee-searchprofile-component .step-img {
  position: absolute;
  width: 50px;
  height: 50px;
  font-size: 2rem;
  text-align: center;
  color: $primary;
}

@media (min-width: 768px) {
  skarabee-searchprofile-component .step-img {
    top: -2rem
  }
}

skarabee-searchprofile-component .step__btn {
  flex: 1;
  list-style: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer
}

skarabee-searchprofile-component .step__btn a {
  font-size: 1.25rem;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0
}

@media (min-width: 768px) {
  skarabee-searchprofile-component .step__btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    margin-top: 4rem
  }
}

skarabee-searchprofile-component .step__btn:after, skarabee-searchprofile-component .step__btn:before {
  position: absolute;
  width: 100%;
  height: 1px;
  background: $primary;
  z-index: 1;
}

@media (min-width: 768px) {
  skarabee-searchprofile-component .step__btn:after, skarabee-searchprofile-component .step__btn:before {
    content: ""
  }
}

skarabee-searchprofile-component .step__btn:after {
  transform: scaleX(0);
  transform-origin: left center;
  transition: all .48s ease-out
}

skarabee-searchprofile-component .subscribe-form--default .step__btn {
  padding-top: 0;
  opacity: 1;
}

skarabee-searchprofile-component .subscribe-form--default .step__btn a {
  margin-top: 0;
  width: 3rem;
  height: 3rem;
  color: $primary;
  border-radius: 50%;
  border: 1px solid $primary;
  background: white;
  position: relative;
  z-index: 10;
}

skarabee-searchprofile-component .subscribe-form--default .step__btn a:hover {
  text-transform: none
}

skarabee-searchprofile-component .subscribe-form--default .step__btn.active {
  opacity: 1
}

skarabee-searchprofile-component .subscribe-form--default .step__btn.active a {
  border-color: $primary;
  border-width: 1px;
  background: $primary;
  color: $white;
  position: relative;
  z-index: 10;
}

skarabee-searchprofile-component .subscribe-form--default .step__btn.active:after {
  background: $primary;
  transform: scaleX(1)
}

skarabee-searchprofile-component .jumbotron {
  min-height: 200px;
  padding: 2.5rem !important;
}

@media (min-width: 576px) {
  skarabee-searchprofile-component .jumbotron {
    padding: 3.75rem !important;
    margin-bottom: 0 !important
  }
}

skarabee-searchprofile-component skarabee-elements-step-title {
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  > div {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    * {
      margin: 0;
    }
  }
}

skarabee-searchprofile-component skarabee-elements-step-title p {
  justify-content: center;
  display: flex
}

skarabee-searchprofile-component .sentpanel {
  display: flex;
  flex-direction: column;
  align-items: center
}

skarabee-searchprofile-component .sentpanel p {
  margin-top: 2rem
}

skarabee-searchprofile-component .hidden {
  display: none
}

skarabee-searchprofile-component .btn-group .btn-outline-primary {
  padding: 1rem !important
}

skarabee-searchprofile-component .btn-primary:not(.disabled) {
  background-color: $primary !important;
  color: #fff !important;
  border-color: $primary !important;
  font-size: 0.8rem;
  font-weight: 600;
  position: relative;
  text-transform: none;
  padding: 1rem;
}

skarabee-searchprofile-component .btn-primary:not(.disabled):hover {
  color: #fff !important
}

skarabee-searchprofile-component .btn-outline-primary {
  color: $primary !important;
  border-color: $primary !important;
}

skarabee-searchprofile-component .btn-outline-primary.selected, skarabee-searchprofile-component .btn-outline-primary:hover {
  background-color: $primary !important;
  color: #fff !important;
  border-color: $primary !important;
}

skarabee-searchprofile-component .btn-outline-primary:not(:disabled):not(.disabled).active, skarabee-searchprofile-component .btn-outline-primary:not(:disabled):not(.disabled):active, skarabee-searchprofile-component .show > .btn-outline-primary.dropdown-toggle {
  background-color: $primary !important;
  color: #fff !important
}

skarabee-searchprofile-component .wizard .stepbuttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px $dark;
}

skarabee-searchprofile-component .wizard .stepbuttons a.previous {
  color: $primary !important;
}

skarabee-searchprofile-component .row-remarks {
  padding-top: 1rem
}

skarabee-searchprofile-component .row-termsofservice {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end
}

skarabee-searchprofile-component p.requiredMessage {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 95%;
  font-style: italic;
  font-weight: 600;
  color: $dark;
}

skarabee-searchprofile-component .form-control {
  border: solid 1px $primary;
  width: 100%;
  border-radius: 0;
  &:focus {
    border: solid 1px $primary;
  }
}


skarabee-searchprofile-component .form-control:focus {
  outline: none;
  border-bottom: 1px solid #2e2e2e
}

skarabee-searchprofile-component .form-control.ng-invalid.ng-touched {
  background: rgba(220, 53, 69, .2)
}

skarabee-searchprofile-component .validation-message {
  display: none;
  color: #dc3545;
  font-size: 80%;
  line-height: 24px;
  font-weight: 700;
  padding: 4px 8px
}

skarabee-searchprofile-component.ng-submitted .validation-message, skarabee-searchprofile-component .validation-message.validation-error {
  display: block
}
